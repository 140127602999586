import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {


  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content reveal-from-bottom">
            <Image
              src={require('./../../assets/images/cloudsnipp-logo.svg')}
              alt="Logo"
              width={200}
              height={200} />
            <h1 className="mt-0 mb-16">
              CloudSnipp
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32">
                The best <span className="text-color-primary">screen capture</span> experience
                </p>
              <div className="download-button">
                <Button tag="a" color="primary" wideMobile href="/versions/CloudSnipp-1.2.0-setup.msi">
                  <Image
                    src={require('./../../assets/images/win.svg')}
                    alt="Windows logo"
                    width={15}
                  />Download
                    </Button>
                <div className="download-info">CloudSnipp is FREE forever. <br />Latest version <span className="bold">v1.2.0</span> released on <span className="bold">13.12.2020</span>.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
